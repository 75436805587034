import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

  async getBulkFieldsData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.bulkfieldsdata().then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async uploadFile({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.uploadfile(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },

 async updateLeadFile({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.updateleadfile(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  }, 
async updateClickLeadFile({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.updateclickleadfile(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },   
async deleteBulk({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.deleteBulk(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },  
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
