import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {
async loadNewLeads({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.loadNewLeads(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async approveLeads({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.approveLeads(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async rejectLeads({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.rejectLeads(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
