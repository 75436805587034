import axios from "axios";
import { setItem, getItem, removeItem } from "../utility/localStorageControl";
const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const authHeader = () => ({
  Authorization: `Bearer ${getItem("access_token")}`,
});
class DashboardService {
  getoverview(tenent_ids) {
    var tenent_id = tenent_ids.ids;
    var refresh = tenent_ids.refresh;
    // console.log(tenent_id);
    // console.log(refresh);
    var user = JSON.parse(localStorage.getItem("user"));
    var data = new FormData();
    data.append("company_id", user.company_id);
    data.append("tenent_id", tenent_id);
    data.append("refresh", refresh);
    // console.log(data);
    return axios
      .post(API_ENDPOINT + "api/auth/getoverview", data, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        if (response.status == 200) {
          return response;
        } else {
          return response;
        }
      });
  }
}

export default new DashboardService();
