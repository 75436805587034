import axios from 'axios';
import { setItem, getItem, removeItem } from '../utility/localStorageControl';

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
localStorage.setItem("search_data",JSON.stringify([]));


const authHeader = () => ({
    Authorization: `Bearer ${getItem('access_token')}`,
});

class XeroService {
    sendrfp(api_data) { 
        //var email = localStorage.getItem('email_rfp');
        var data = new FormData();
        data.append('email', api_data['email']);
        data.append('name', api_data['name']);
        return axios
            .post(API_ENDPOINT + 'api/auth/sendrfplink', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadsdata(api_data) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('user', user.user_id);
        data.append('startdate',  api_data['startdate']);
        data.append('enddate',  api_data['enddate']);
        data.append('prop',  api_data['prop']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getleadsdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    getcampdetails(api_data) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('user', user.user_id);
        return axios
            .post(API_ENDPOINT + 'api/auth/getCampaignsDetails', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    loadcampdetails(api_data) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('user', user.user_id);
        data.append('campaign_id',api_data['campaign_id'])
        return axios
            .post(API_ENDPOINT + 'api/auth/getSentMailsData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadsDataIndex(api_data) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('ids',  api_data['ids']);
        data.append('user', user.user_id);
        data.append('company_id', user.company_id);
        data.append('role_id', user.role_id);
        
        return axios
            .post(API_ENDPOINT + 'api/auth/getleadsdataindex', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadsDataIndexx(api_data) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('ids',  api_data['ids']);
        data.append('user', user.user_id);
        data.append('company_id', user.company_id);
        data.append('role_id', user.role_id);
        return axios
            .post(API_ENDPOINT + 'api/auth/getleadsdataindexx', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    getDashboardCounts() {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('id', user.id);
        data.append('user', user.user_id);
         data.append('company_id', user.company_id);
         data.append('role_id', user.role_id);
        return axios
            .post(API_ENDPOINT + 'api/auth/getdashboardcounts', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadscomplete(api_data) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('ids', api_data['ids']);
        data.append('user', user.user_id);
        data.append('company_id', user.company_id);
        data.append('role_id', user.role_id);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadscomplete', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    clickscomplete(api_data) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('ids', api_data['ids']);
        data.append('source', api_data['source'])
        data.append('user', user.user_id);
        data.append('company_id', user.company_id);
        data.append('role_id', user.role_id);
        return axios
            .post(API_ENDPOINT + 'api/auth/clickscomplete', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadscentersdata() {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('company_id', user.id);
        return axios
            .post(API_ENDPOINT + 'api/auth/getcentersdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    getleadsindex(api_data){
        var data = new FormData();
        data.append('ids',  api_data['ids']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getleadsindex', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadqualification(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('company_id',user.company_id);
        data.append('ids',  api_data['ids']);
        data.append('status', api_data['current_status']);
        data.append('manager', api_data['manager']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadqualification', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadProposalReview(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('ids',  api_data['ids']);
        data.append('status', api_data['current_status']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadproposalreview', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadReviewAccepted(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('ids',  api_data['ids']);
        data.append('selectedFile',  api_data['selectedFile']);
        data.append('status', api_data['current_status']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadreviewaccepted', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadAcceptedProposal(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('ids',  api_data['ids']);
        data.append('status', api_data['current_status']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadacceptedproposal', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadRejectedProposal(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('ids',  api_data['ids']);
        data.append('status', api_data['current_status']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadrejectedproposal', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadRejectedReview(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('ids',  api_data['ids']);
        data.append('status', api_data['current_status']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadrejectedreview', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    addScheduleIndex(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('ids',  api_data['ids']);
        data.append('click_id',  api_data['click_id']);
        data.append('ScheduleType',  api_data['ScheduleType']);
        data.append('name',  api_data['name']);
        data.append('calltype',  api_data['calltype']);
        data.append('contact_type', api_data['contact_type']);
        data.append('date',  api_data['date']);
        data.append('hour',  api_data['hour']);
        data.append('min',  api_data['min']);
        data.append('assigned',  api_data['assigned']);
        data.append('note',  api_data['note']);
        data.append('current_status',  api_data['current_status']);
        return axios
            .post(API_ENDPOINT + 'api/auth/addscheduleindex', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadtotarget(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('company_id',user.company_id);
        data.append('ids',  api_data['ids']);
        data.append('current_status',  api_data['current_status']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadtotarget', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadproposal(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('ids',  api_data['ids']);
        data.append('status',  api_data['current_status']);
        data.append('selectedFile',  api_data['selectedFile']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadproposal', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadclosedlost(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('ids',  api_data['ids']);
        data.append('status',  api_data['current_status']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadlost', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leaddead(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('ids',  api_data['ids']);
        data.append('click_id',  api_data['click_id']);
        data.append('comment',  api_data['comment']);
        data.append('notes',  api_data['notes']);
        data.append('status',  api_data['current_status']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leaddead', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    getcampaign(api_data){
        var data = new FormData();
        data.append('ids',  api_data['ids']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getcampaign', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    getcall(api_data){
        var data = new FormData();
        data.append('ids',  api_data['ids']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getcall', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    gethistory(api_data){
        var data = new FormData();
        data.append('ids',  api_data['ids']);
        return axios
            .post(API_ENDPOINT + 'api/auth/gethistory', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    getcomment(api_data){
        var data = new FormData();
        data.append('ids',  api_data['ids']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getcomment', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadUpdate(api_data){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('id',  api_data['id']);
        data.append('company',  api_data['company']);
        data.append('industry',  api_data['industry']);
        data.append('website',  api_data['website']);
        data.append('turnover',  api_data['turnover']);
        data.append('noemployees',  api_data['noemployees']);
        data.append('address1',  api_data['address1']);
        data.append('address2',  api_data['address2']);
        data.append('city',  api_data['city']);
        data.append('country',  api_data['country']);
        data.append('postal',  api_data['postal']);
        data.append('name',  api_data['name']);
        data.append('jobtitle',  api_data['jobtitle']);
        data.append('dept',  api_data['dept']);
        data.append('interested',  api_data['interested']);
        data.append('board',  api_data['board']);
        data.append('mobile',  api_data['mobile']);
        data.append('phone',  api_data['phone']);
        data.append('email',  api_data['email']);
        data.append('center',  api_data['center']);
        data.append('linkedin',  api_data['linkedin']);
        data.append('opportunity',  api_data['opportunity']);
        data.append('leadsource',  api_data['leadsource']);
        data.append('probability',  api_data['probability']);
        data.append('email_unsubscribe',  api_data['email_unsubscribe']);
        data.append('management_level',  api_data['management_level']);
        data.append('founded_year',  api_data['founded_year']);
        data.append('facebook',  api_data['facebook']);
        data.append('twitter',  api_data['twitter']);
        data.append('sla',  api_data['sla']); 
        data.append('live',  api_data['live']);       
        return axios
            .post(API_ENDPOINT + 'api/auth/leadupdate',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    leadDuplicate(api_data){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user',user.user_id);
        data.append('company_id',user.company_id);
        data.append('id',  api_data['id']);
        data.append('company',  api_data['company']);
        data.append('industry',  api_data['industry']);
        data.append('website',  api_data['website']);
        data.append('turnover',  api_data['turnover']);
        data.append('noemployees',  api_data['noemployees']);
        data.append('address1',  api_data['address1']);
        data.append('address2',  api_data['address2']);
        data.append('city',  api_data['city']);
        data.append('country',  api_data['country']);
        data.append('postal',  api_data['postal']);
        data.append('name',  api_data['name']);
        data.append('jobtitle',  api_data['jobtitle']);
        data.append('dept',  api_data['dept']);
        data.append('interested',  api_data['interested']);
        data.append('board',  api_data['board']);
        data.append('mobile',  api_data['mobile']);
        data.append('email',  api_data['email']);
        data.append('center',  api_data['center']);
        data.append('linkedin',  api_data['linkedin']);
        data.append('opportunity',  api_data['opportunity']);
        data.append('leadsource',  api_data['leadsource']);
        data.append('probability',  api_data['probability']);
        data.append('email_unsubscribe',  api_data['email_unsubscribe']);
        data.append('management_level',  api_data['management_level']);
        data.append('founded_year',  api_data['founded_year']);
        data.append('facebook',  api_data['facebook']);
        data.append('twitter',  api_data['twitter']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadduplicate',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    addInfoIndex(api_data){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('ids',  api_data['ids']);
        data.append('user',  user.user_id);
        data.append('type',  api_data['type']);
        data.append('assignedto',  api_data['assignedto']);
        data.append('info',  api_data['info']);
        data.append('status', api_data['current_status']);
        return axios
            .post(API_ENDPOINT + 'api/auth/addinfo', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
    addComIndex(api_data){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('ids',  api_data['ids']);
        data.append('user',  user.user_id);
        data.append('type',  api_data['type']);
        data.append('info',  api_data['info']);
        return axios
            .post(API_ENDPOINT + 'api/auth/addcom', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }   
    addCentersData(api_data){
        var data = new FormData();
        data.append('ccode',  api_data['ccode']);
        data.append('cname',  api_data['cname']);
        console.log(data);
        return axios
            .post(API_ENDPOINT + 'api/auth/addcentersdata', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
assignfieldsdata(){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user', user.user_id);
        data.append('company_id', user.company_id);
        return axios
            .post(API_ENDPOINT + 'api/auth/getassignfieldsdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
bulkfieldsdata(){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('user', user.user_id);
        return axios
            .post(API_ENDPOINT + 'api/auth/getbulkfieldsdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }    
getLoadLeadsData(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',  user.user_id);
        data.append('company_id',  user.company_id);
        data.append('role',  user.role_name);
        data.append('type',  api_data['type']);
        data.append('center',  api_data['center']);
        data.append('fromUser',  api_data['fromUser']);
        data.append('toUser',  api_data['toUser']);
        data.append('industry',  api_data['industry']);
        data.append('status',  api_data['status']);
        data.append('interest',  api_data['interest']);
        //data.append('company_id', user.id);
        return axios
            .post(API_ENDPOINT + 'api/auth/getleadsloaddata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }    
assignleadsdata(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id)
        data.append('ids',api_data['selectedid'])
        data.append('type',  api_data['type']);
        data.append('center',  api_data['center']);
        data.append('fromUser',  api_data['fromUser']);
        data.append('toUser',  api_data['toUser']);
        data.append('industry',  api_data['industry']);
        data.append('status',  api_data['status']);
        data.append('interest',  api_data['interest']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getassignleadsdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
loadNewLeads(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',  user.user_id);
        data.append('company_id',  user.company_id);
        data.append('role',  user.role_name);
        //data.append('company_id', user.id);
        return axios
            .post(API_ENDPOINT + 'api/auth/loadNewLeads',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
approveLeads(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id)
        data.append('company_id',  user.company_id);
        data.append('ids',api_data['selectedid'])
        data.append('center',  api_data['center']);
        data.append('toUser',  api_data['toUser']);
        data.append('industry',  api_data['industry']);
        data.append('interest',  api_data['interest']);
        return axios
            .post(API_ENDPOINT + 'api/auth/approveLeads',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    } 
rejectLeads(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',user.user_id)
        data.append('ids',api_data['selectedid'])
        return axios
            .post(API_ENDPOINT + 'api/auth/rejectLeads',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    } 
deleteBulk(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('ids',api_data['selectedid'])
        return axios
            .post(API_ENDPOINT + 'api/auth/deletebulk',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }          
searchleadsdata(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',  user.user_id);
        data.append('role_id',  user.role_id);
        data.append('criteria',  api_data['criteria']);
        data.append('condition',  api_data['condition']);
        data.append('company_id',  user.company_id);
        data.append('term',  api_data['term']);
        data.append('team',  api_data['team']);
        data.append('industry',  api_data['industry']);
        data.append('status',  api_data['status']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getleadssearchdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
leadMailSent(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',  user.user_id);
        data.append('role_id',  user.role_id);
        data.append('ids',  api_data['ids']);
        return axios
            .post(API_ENDPOINT + 'api/auth/leadmailsent',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }    
dailyleadsdata(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',  user.user_id);
        data.append('role_id',  user.role_id);
        data.append('company_id',  user.company_id);
        data.append('center',  api_data['center']);
        data.append('industry',  api_data['industry']);
        data.append('startdate',  api_data['startdate']);
        data.append('enddate',  api_data['enddate']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getdailyleadsdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }   
statusleadsdata(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',  user.user_id);
        data.append('company_id',  user.company_id);
        data.append('role_id',  user.role_id);
        data.append('center',  api_data['center']);
        data.append('selected_user',  api_data['selected_user']);
        data.append('startdate',  api_data['startdate']);
        data.append('enddate',  api_data['enddate']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getstatusleadsdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
selectstatusleadsdata(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',  user.user_id);
        data.append('company_id',  user.company_id);
        data.append('role_id',  user.role_id);
        data.append('center',  api_data['center']);
        data.append('status',  api_data['status']);
        data.append('selected_user',  api_data['selected_user']);
        data.append('startdate',  api_data['startdate']);
        data.append('enddate',  api_data['enddate']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getselectstatusleadsdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }    
currentleadsdata(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',  user.user_id);
        data.append('role_id',  user.role_id);
        data.append('company_id',  user.company_id);
        data.append('center',  api_data['center']);
        data.append('industry',  api_data['industry']);
        data.append('startdate',  api_data['startdate']);
        data.append('enddate',  api_data['enddate']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getcurrentleadsdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    } 
followupleadsdata(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',  user.user_id);
        data.append('role_id',  user.role_id);
        data.append('company_id',  user.company_id);
        data.append('center',  api_data['center']);
        data.append('industry',  api_data['industry']);
        data.append('startdate',  api_data['startdate']);
        data.append('enddate',  api_data['enddate']);
        return axios
            .post(API_ENDPOINT + 'api/auth/getfollowupleadsdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
clickstagdata(api_data){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user',  user.user_id);
        data.append('role_id',  user.role_id);
        data.append('company_id',  user.company_id);
        data.append('center',  api_data['center']);
        data.append('interest',  api_data['interest']);
        data.append('category',  api_data['category']);
        data.append('startdate',  api_data['startdate']);
        data.append('enddate',  api_data['enddate']);
        return axios
            .post(API_ENDPOINT + 'api/auth/clickstagdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }      
uploadfile(api_data){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user', user.user_id);
        data.append('company_id', user.company_id);
        data.append('center',  api_data['center']);
        data.append('interest',  api_data['interest']);
        data.append('leadSource',  api_data['leadSource']);
        data.append('selectedFile',  api_data['selectedFile']);
        return axios
            .post(API_ENDPOINT + 'api/auth/uploadfiles',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
updateleadfile(api_data){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user', user.user_id);
        data.append('company_id', user.company_id);
        data.append('center',  api_data['center']);
        data.append('interest',  api_data['interest']);
        data.append('leadSource',  api_data['leadSource']);
        data.append('selectedFile',  api_data['selectedFile']);
        return axios
            .post(API_ENDPOINT + 'api/auth/updateleadfiles',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
updateclickleadfile(api_data){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user', user.user_id);
        data.append('company_id', user.company_id);
        data.append('center',  api_data['center']);
        data.append('selectedFile',  api_data['selectedFile']);
        return axios
            .post(API_ENDPOINT + 'api/auth/updateclickleadfiles',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }         
addtargetdata(api_data){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user', user.user_id);
        data.append('company_id', user.company_id);
        data.append('company',  api_data['company']);
        data.append('industry',  api_data['industry']);
        data.append('website',  api_data['website']);
        data.append('turnover',  api_data['turnover']);
        data.append('noemployees',  api_data['noemployees']);
        data.append('address1',  api_data['address1']);
        data.append('address2',  api_data['address2']);
        data.append('city',  api_data['city']);
        data.append('country',  api_data['country']);
        data.append('postal',  api_data['postal']);
        data.append('name',  api_data['name']);
        data.append('jobtitle',  api_data['jobtitle']);
        data.append('dept',  api_data['dept']);
        data.append('interested',  api_data['interested']);
        data.append('board',  api_data['board']);
        data.append('mobile',  api_data['mobile']);
        data.append('email',  api_data['email']);
        data.append('center',  api_data['center']);
        data.append('linkedin',  api_data['linkedin']);
        data.append('opportunity',  api_data['opportunity']);
        data.append('leadsource',  api_data['leadsource']);
        data.append('assignedto',  api_data['assignedto']);
        return axios
            .post(API_ENDPOINT + 'api/auth/addtargetdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }
addopportunitydata(api_data){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user', user.user_id);
        data.append('company_id', user.company_id);
        data.append('company',  api_data['company']);
        data.append('industry',  api_data['industry']);
        data.append('website',  api_data['website']);
        data.append('turnover',  api_data['turnover']);
        data.append('noemployees',  api_data['noemployees']);
        data.append('address1',  api_data['address1']);
        data.append('address2',  api_data['address2']);
        data.append('city',  api_data['city']);
        data.append('country',  api_data['country']);
        data.append('postal',  api_data['postal']);
        data.append('name',  api_data['name']);
        data.append('jobtitle',  api_data['jobtitle']);
        data.append('dept',  api_data['dept']);
        data.append('interested',  api_data['interested']);
        data.append('board',  api_data['board']);
        data.append('mobile',  api_data['mobile']);
        data.append('email',  api_data['email']);
        data.append('center',  api_data['center']);
        data.append('linkedin',  api_data['linkedin']);
        data.append('opportunity',  api_data['opportunity']);
        data.append('leadsource',  api_data['leadsource']);
        data.append('assignedto',  api_data['assignedto']);
        return axios
            .post(API_ENDPOINT + 'api/auth/addopportunitydata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }         
manualfieldsdata(){
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user', user.user_id);
        data.append('company_id', user.company_id);
        data.append('role_id', user.role_id);
        
        return axios
            .post(API_ENDPOINT + 'api/auth/getmanualfieldsdata',  data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }             

}

export default new XeroService();
