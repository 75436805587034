import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

async searchLeads({ commit },data) {
  return new Promise((resolve, reject) => {
      XeroService.searchleadsdata(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },


}
export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
