import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

async getLeadsData({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadsdata(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async sendRfpLink({ commit },data) {
      return new Promise((resolve, reject) => {
      XeroService.sendrfp(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject("Wrong email id or password ");
        }
      });
    });
  },  
async getLeadsDataIndex({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadsDataIndex(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },  
async getLeadsDataIndexx({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadsDataIndexx(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async leadMailSent({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadMailSent(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },  
async getDashboardCounts({ commit },) {
    return new Promise((resolve, reject) => {
      XeroService.getDashboardCounts().then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },  
async getLeadsCompleteStatus({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadscomplete(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  }, 
async getClicksCompleteStatus({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.clickscomplete(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async getCampDetails({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.getcampdetails(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  }, 
async loadCampDetails({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.loadcampdetails(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },     

};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
