import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

async manualFieldsData({ commit },) {
  return new Promise((resolve, reject) => {
      XeroService.manualfieldsdata().then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async addopportunitydata({ commit },data) {
  return new Promise((resolve, reject) => {
      XeroService.addopportunitydata(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async addtargetdata({ commit },data) {
  return new Promise((resolve, reject) => {
      XeroService.addtargetdata(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
}
export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
