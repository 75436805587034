export default [ 
  {
    path: "",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/view/dashboard/Home.vue"),
    props: true,
  },
  {
    path: "/send-rfp",
    name: "send-rfp",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/view/dashboard/rfp/SendRfp.vue"),
    props: true,
  },
  {
    path: "/index/:id/:click_id",
    name: "index",
    component: () =>
      import(
       /* webpackChunkName: "Dashboard" */ "@/view/dashboard/admin/indexer/IndexerForm.vue"
     ),
   props: true,
  },
  {
    path: "/sprint/:id",
    name: "sprint",
    component: () =>
      import(
       /* webpackChunkName: "Dashboard" */ "@/view/dashboard/sprint/new/SprintForm.vue"
     ),
   props: true,
  },
  {
    path: "/nxtsprint/:id",
    name: "nxtsprint",
    component: () =>
      import(
       /* webpackChunkName: "Dashboard" */ "@/view/dashboard/sprint/next/NextSprint.vue"
     ),
   props: true,
  },
  {
    path: "/clksprint/:id",
    name: "clksprint",
    component: () =>
      import(
       /* webpackChunkName: "Dashboard" */ "@/view/dashboard/sprint/next/ClickSprint.vue"
     ),
   props: true,
  },
  {
    path: "/opnsprint/:id",
    name: "opnsprint",
    component: () =>
      import(
       /* webpackChunkName: "Dashboard" */ "@/view/dashboard/sprint/next/OpenSprint.vue"
     ),
   props: true,
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/view/dashboard/user/Profile.vue"),
    props: true,
  },
  {
    path: "users",
    name: "users",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/admin/usermaster/UserMaster.vue"
      ),
    props: true,
  },
  {
    path: "roles",
    name: "roles",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/admin/rolemaster/RoleMaster.vue"
      ),
    props: true,
  },
  {
    path: "centers",
    name: "centers",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/admin/centers/CentersForm.vue"
      ),
    props: true,
  },
  {
    path: "assign",
    name: "assign",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/admin/assign/AssignForm.vue"
      ),
    props: true,
  },
  {
    path: "new",
    name: "new",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/admin/new/NewLeadsForm.vue"
      ),
    props: true,
  },
  {
    path: "fresh-data",
    name: "fresh-data",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/admin/bulk/FreshDataForm.vue"
      ),
    props: true,
  },
  {
    path: "data-update",
    name: "data-update",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/admin/bulk/UpdateDataForm.vue"
      ),
    props: true,
  },
  {
    path: "clicks-update",
    name: "clicks-update",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/admin/bulk/ClickDataForm.vue"
      ),
    props: true,
  },
  {
    path: "search",
    name: "search",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/sales/search/SearchForm.vue"
      ),
    props: true,
  },
  {
    path: "new-leads",
    name: "new-leads",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/sales/newleads/NewLeadsForm.vue"
      ),
    props: true,
  },
  {
    path: "daily",
    name: "daily",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/reports/daily/DailyReport.vue"
      ),
    props: true,
  },
  {
    path: "status",
    name: "status",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/reports/status/StatusReport.vue"
      ),
    props: true,
  },
  {
    path: "select-status",
    name: "select-status",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/reports/status/SelectStatusReport.vue"
      ),
    props: true,
  },
  {
    path: "status-changed",
    name: "status-changed",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/reports/current_status/CurrentStatus.vue"
      ),
    props: true,
  },
  {
    path: "follow-up",
    name: "follow-up",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/reports/pendingfollowups/FollowupReport.vue"
      ),
    props: true,
  },
  {
    path: "clicks-tag",
    name: "clicks-tag",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/reports/pendingfollowups/ClicksReport.vue"
      ),
    props: true,
  },
];
