import mutations from './mutations';
import RoleService from '../../../services/role.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});
 

const actions = {
  async roleGetData({ commit }, data) {
    try {
      commit('readBegin');
      commit('readSuccess', data);
    } catch (err) {
      commit('readErr', err);
    }
  },

  async roleData({ commit }, data) {
    return new Promise((resolve, reject) => {
      RoleService.getroledata(data).then(
        (response) => {
          if (response.status == 200) {
            resolve(response)
          } else {
            reject(response)
          }
        }
      );
    })
  },


  async  getPermissionData({ commit }) {
    return new Promise((resolve, reject) => {
      RoleService.getpermission().then(
        (response) => {
          if (response.status == 200) {
            resolve(response)
          } else {
            reject(response)
          }
        }
      );
    })
  },

  async rolemasterSearchData({ commit }, roledata) {
    try {
      commit('readBegin');
      const data = roledata.userdata.filter(item => item.role_name.toUpperCase().startsWith(roledata.serchtext.toUpperCase()));
      commit('readSuccess', data);
    } catch (err) {
      commit('readErr', err);
    }
  },


  async roleAddData({ commit }, data) {
    return new Promise((resolve, reject) => {
      RoleService.roleAdd(data).then(
        (response) => {
          if (response.status < 202) {
            // commit('logoutBegin');
            // Cookies.remove('logedIn');
            // commit('logoutSuccess', null);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async roleUpdateData({ commit }, data) {
    return new Promise((resolve, reject) => {
      RoleService.roleEdit(data).then(
        (response) => {
          if (response.status < 202) {
            // commit('logoutBegin');
            // Cookies.remove('logedIn');
            // commit('logoutSuccess', null);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },


  async roleDeleteData({ commit }, data) {
    try {
      return new Promise((resolve, reject) => {
        RoleService.roleDelete(data.role_id).then(
          (response) => {
            commit('readBegin');
            commit('readSuccess', data.data);
          }
        );
      })
      
    } catch (err) {
      commit('readErr', err);
    }
  },

  // async usermasterSearchData({ commit }, userdata) {

  //   try {
  //     commit('readBegin');
  //     const data = userdata.userdata.filter(item => item.fullname.toUpperCase().startsWith(userdata.serchtext.toUpperCase()));
  //     commit('readSuccess', data);
  //   } catch (err) {
  //     commit('readErr', err);
  //   }
  // },

  // async getroledropdata() {
  //   return new Promise((resolve, reject) => {
  //     UserService.getrolelist().then(
  //       (response) => { 
  //         if (response.status == 200) {
  //           resolve(response)
  //         } else {
  //           reject(response)
  //         }
  //       },
  //       error => {
  //         reject('Error Comunicating To Server')
  //       }
  //     );
  //   })
  // },

  // async onStarUpdate({ commit }, { data, id }) {
  //   try {
  //     commit('starUpdateBegin');
  //     data.map(item => {
  //       if (item.id === id) {
  //         const fav = item;
  //         if (item.stared) {
  //           fav.stared = false;
  //         } else {
  //           fav.stared = true;
  //         }
  //       }
  //       return commit('starUpdateSuccess', data);
  //     });
  //   } catch (err) {
  //     commit('starUpdateErr', err);
  //   }
  // },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
