import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

  async getLeadsIndexData({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.getleadsindex(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async loadLeads({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.loadleadsdata(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async leadQualification({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadqualification(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  async leadProposalReview({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadProposalReview(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
   async leadReviewAccepted({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadReviewAccepted(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  async leadAcceptedProposal({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadAcceptedProposal(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  async leadRejectedProposal({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadRejectedProposal(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  async leadRejectedReview({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadRejectedReview(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
 async leadDead({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leaddead(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  async leadToTarget({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadtotarget(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  async leadProposal({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadproposal(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  async leadClosedLost({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadclosedlost(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  async addScheduleIndex({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.addScheduleIndex(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async getCampaignIndex({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.getcampaign(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  async getCallIndex({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.getcall(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async getHistoryIndex({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.gethistory(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async getCommentIndex({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.getcomment(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },  
async leadUpdateData({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadUpdate(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
async leadDuplicateData({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.leadDuplicate(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },  
async addInfoIndex({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.addInfoIndex(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },  
async addComIndex({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.addComIndex(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
};

  


export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
