import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

  async getCentersData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.leadscentersdata().then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },
  async addCenters({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.addCentersData(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            
            reject(response)
            
          }
        }
      );
    })
  },

};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
