import mutations from "./mutations";
import Cookies from "js-cookie";
import AuthService from "../../../../services/auth.service";
import XeroService from "../../../../services/xero.service";
import DashService from "../../../../services/dashboard.service";
import UserService from "../../../../services/user.service";

const state = () => ({
  login: Cookies.get("logedIn"),
  loading: false,
  error: null,
});

const actions = {
  async register({ commit }, user) {
    return new Promise((resolve, reject) => {
      AuthService.register(user).then(
        (response) => {
          commit("loginBegin");
          if (response.status == 200) {
            Cookies.set("logedIn", true);
            commit("loginSuccess", true);
            resolve(response);
          } else {
            commit("loginErr", "Details Already Exist ");
            reject(response);
          }
        },
        (error) => {
          commit("loginErr", error);
          reject("Error Comunicating To Server");
        }
      );
    });
  },
  
  async updateProfile({ commit }, user) {
    
    return new Promise((resolve, reject) => {
      AuthService.updateProfile(user).then(
        (response) => {
          commit("loginBegin");
          if (response.status == 200) {
            Cookies.set("logedIn", true);
            commit("loginSuccess", true);
            resolve(response);
          } else {
            commit("loginErr", "Details Already Exist ");
            reject(response);
          }
        },
        (error) => {
          
          commit("loginErr", error);
          reject("Error Comunicating To Server");
          
        }
      );
    });
  },
  async signup({ commit }, user) {
    
    return new Promise((resolve, reject) => {
      AuthService.signup(user).then(
        (response) => {
          commit("loginBegin");
          if (response.status == 200) {
            Cookies.set("logedIn", true);
            commit("loginSuccess", true);
            resolve(response);
          } else {
            commit("loginErr", "Details Already Exist ");
            reject(response);
          }
        },
        (error) => {
          
          commit("loginErr", error);
          reject("Error Comunicating To Server");
          
        }
      );
    });
  },

  async login({ commit }, user) {
    return new Promise((resolve, reject) => {
      AuthService.login(user).then(
        (response) => {
          commit("loginBegin");
          if (response.status == 200) {
            Cookies.set("logedIn", true);
            commit("loginSuccess", true);
            resolve(response);
          } else {
            commit("loginErr", "Wrong email id or password ");
            reject(response);
          }
        },
        (error) => {
          commit("loginErr", error);
          reject("Error Comunicating To Server");
        }
      );
    });
  },
  async logOut({ commit }) {
    return new Promise((resolve, reject) => {
      AuthService.logout().then((response) => {
        if (response.status == 200) {
          commit("logoutBegin");
          Cookies.remove("logedIn");
          commit("logoutSuccess", null);
          resolve(response);
        } else {
          commit("logoutErr", response);
          reject("Wrong email id or password ");
        }
      });
    });
  },
  // async sendRfpLink({ commit },data) {
  //   return new Promise((resolve, reject) => {
  //     XeroService.sendrfp().then((response) => {
  //       if (response.status == 200) {
  //         resolve(response);
  //       } else {
  //         reject("Wrong email id or password ");
  //       }
  //     });
  //   });
  // },
  async userdata({ commit }) {
    return new Promise((resolve, reject) => {
      AuthService.userData().then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            commit("logoutBegin");
            Cookies.remove("logedIn");
            commit("logoutSuccess", null);
            reject(response);
          }
        },
        () => {
          commit("logoutBegin");
          Cookies.remove("logedIn");
          commit("logoutSuccess", null);
          reject("Error Comunicating To Server");
        }
      );
    });
  },
  
  async xerodata() {
    return new Promise((resolve, reject) => {
      XeroService.getorgdata().then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        () => {
          reject("Error Comunicating To Server");
        }
      );
    });
  },

  async xerosave({ commit }, tenent_id) { 
    return new Promise((resolve, reject) => {
      XeroService.xerosave(tenent_id).then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        () => {
          reject("Error Comunicating To Server");
        }
      );
    });
  },

  async xerodisconnect({ commit },tenent_id) { 
    return new Promise((resolve, reject) => {
      XeroService.xerodisconnect(tenent_id).then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        () => {
          reject("Error Comunicating To Server");
        }
      );
    });
  },

  async xerorefresh({ commit },tenent_id) { 
    return new Promise((resolve, reject) => {
      XeroService.xerorefresh(tenent_id).then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        () => {
          reject("Error Comunicating To Server");
        }
      );
    });
  },
  
  async changeorg({ commit }, tenent_id) { 
    return new Promise((resolve, reject) => {
      XeroService.changeorg(tenent_id).then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        () => {
          reject("Error Comunicating To Server");
        }
      );
    });
  },

  async getoverview({ commit }, id) { 
    return new Promise((resolve, reject) => {
      DashService.getoverview(id).then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        () => {
          reject("Error Comunicating To Server");
        }
      );
    });
  },

  async getsync({ commit }, daterange) { 
    return new Promise((resolve, reject) => {
      XeroService.xerosync(daterange).then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        () => {
          reject("Error Comunicating To Server");
        }
      );
    });
  },
async reposync({ commit }, daterange) { 
    return new Promise((resolve, reject) => {
      XeroService.xeroreposync(daterange).then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        () => {
          reject("Error Comunicating To Server");
        }
      );
    });
  },
  
async addClientsCreds({ commit }, daterange) { 
    return new Promise((resolve, reject) => {
      XeroService.addClientsCred(daterange).then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        () => {
          reject("Error Comunicating To Server");
        }
      );
    });
  },
  async getResync({ commit },tenent_id) { 
    
    return new Promise((resolve, reject) => {
      XeroService.xeroResync(tenent_id).then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        }
        
      );
    });
  },


  // User Mater
  async getuserslist() {
    return new Promise((resolve, reject) => {
      UserService.getuserslist().then(
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        },
        () => {
          reject("Error Comunicating To Server");
        }
      );
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
