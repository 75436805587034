import mutations from './mutations';
import UserService from '../../../services/user.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});
 

const actions = {
  async userGetData({ commit }, data) {
    try {
      commit('readBegin');
      commit('readSuccess', data);
    } catch (err) {
      commit('readErr', err);
    }
  },
  
  async userUpdateData({ commit }, data) {
    return new Promise((resolve, reject) => {
      UserService.userUpdate(data).then(
        (response) => {
          if (response.status == 200) {
            // commit('logoutBegin');
            // Cookies.remove('logedIn');
            // commit('logoutSuccess', null);

            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async userAddData({ commit }, data) {
    return new Promise((resolve, reject) => {
      UserService.userAdd(data).then(
        (response) => {
          if (response.status == 200) {

            resolve(response)
          } else {
            reject(response)
          }
        }
      );
    })
  },


  async userDeleteData({ commit }, id, data) {
    try {
      return new Promise((resolve, reject) => {
        UserService.userDelete(id).then(
          (response) => {
            if (response.status == 200) { 
              resolve(response)
            } else {
              reject(response)
            }
          }
        );
      })
    } catch (err) {
      commit('readErr', err);
    }
  },

  async usermasterSearchData({ commit }, userdata) {

    try {
      commit('readBegin');
      const data = userdata.userdata.filter(item => item.fullname.toUpperCase().startsWith(userdata.serchtext.toUpperCase()));
      commit('readSuccess', data);
    } catch (err) {
      commit('readErr', err);
    }
  },

  async dataFilter({ commit }, userdata) {
    try {
      commit('readBegin');
      const data = userdata.userdata.filter(item => {
        if (userdata.serchtext !== '') {
          return item[userdata.column] === userdata.serchtext;
        }
        return item;
      });
      commit('readSuccess', data);
    } catch (err) {
      commit('readErr', err);
    }
  },

  async getroledropdata() {
    return new Promise((resolve, reject) => {
      UserService.getrolelist().then(
        (response) => { 
          if (response.status == 200) {
            resolve(response)
          } else {
            reject(response)
          }
        },
        error => {
          reject('Error Comunicating To Server')
        }
      );
    })
  },

  async onStarUpdate({ commit }, { data, id }) {
    try {
      commit('starUpdateBegin');
      data.map(item => {
        if (item.id === id) {
          const fav = item;
          if (item.stared) {
            fav.stared = false;
          } else {
            fav.stared = true;
          }
        }
        return commit('starUpdateSuccess', data);
      });
    } catch (err) {
      commit('starUpdateErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
