import { createStore } from "vuex";
import themeLayout from "./modules/themeLayout/actionCreator";
import auth from "./modules/authentication/axios/actionCreator";
import UserMaster from "./modules/usermaster/actionCreator";
import RoleMaster from "./modules/rolemaster/actionCreator";
import LeadsCenters from "./modules/administrator/centers/actionCreator";
import LeadsData from "./modules/administrator/dashboard/actionCreator";
import AssignData from "./modules/administrator/assign/actionCreator";
import NewData from "./modules/administrator/new/actionCreator";
import FreshData from "./modules/administrator/bulk/actionCreator";
import LeadsIndex from "./modules/administrator/indexer/actionCreator";
import SearchData from "./modules/sales/search/actionCreator";
import NewLeads from "./modules/sales/newleads/actionCreator";
import DailyData from "./modules/reports/daily/actionCreator";
import StatusData from "./modules/reports/status/actionCreator";
import CurrentData from "./modules/reports/currentstatus/actionCreator";
import FollowData from "./modules/reports/pendingfollowups/actionCreator";
export default createStore({
  modules: {
    themeLayout,
    auth,
    LeadsCenters,
    LeadsData,
    AssignData,
    LeadsIndex,
    FreshData,
    SearchData,
    StatusData,
    DailyData,
    CurrentData,
    FollowData,
    NewLeads,
    UserMaster,
    RoleMaster,
    NewData,
  },
});
